<template>
  <div>
    <AppTitulo titulo="Comunicado" />

    <div class="blocked-access-wrapper">
      <div class="blocked-access-container">
        <AppMessage>
          <div slot="message-icon">
            <b-icon icon="alert-box" size="is-large" type="is-danger" />
          </div>

          <p slot="message-title">Acesso Bloqueado</p>

          <div slot="message">
            <p>Ops, seu acesso está bloqueado.<br /></p>

            <p class="message-text">
              Entre em contato com a Selcorp pelo celular
              <strong class="celphone-text">(14) 9 9164-9190</strong>, por voz ou WhatsApp.
            </p>
          </div>
        </AppMessage>
      </div>
    </div>
  </div>
</template>

<script>
import AppMessage from '@/components/AppMessage';
import AppTitulo from '@/components/AppTitulo';

export default {
  name: 'BlockedAccess',
  components: {
    AppMessage,
    AppTitulo,
  },
};
</script>

<style lang="scss" scoped>
.celphone-text {
  white-space: nowrap;
}

.blocked-access-container {
  max-width: 615px;
}

.blocked-access-wrapper {
  display: flex;
  justify-content: center;
  padding: 20vh 10vw;
}

.message-text {
  margin-top: 1rem;
}
</style>
